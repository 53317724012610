<template>
  <div>
    <div v-if="isVisibleAllContent">
      <div id="divFeedDetailWrapper" class="feed-item-container" v-if="contentDetail">
        <div class="feed-video" v-if="contentDetail.feed.videoURL">
          <BrandVimeoPlayer
            ref="vimeoPlayer"
            :id="contentDetail.feed.videoURL"
            :data="{ embedVideo: contentDetail.feed.videoURL, title: contentDetail.feed.title }"
          ></BrandVimeoPlayer>
          <div class="feed-video-like">
            <div class="card-video-bottom">
              <VueBadge :radius="20" class="overlay overlay-left overlay-video">
                <LikeButton
                  class="btn-like"
                  :status="contentDetail.feed.liked"
                  :count.sync="contentDetail.feed.likeCount"
                  :contentId="contentDetail.feed.feedId"
                  :contentName="contentDetail.feed.title"
                />

                <span v-if="contentDetail.feed.likeCount || contentDetail.feed.likeCount > 0">{{
                  contentDetail.feed.likeCount
                }}</span>
              </VueBadge>
              <VueBadge :radius="20" class="overlay overlay-right">{{
                contentDetail.feed.category.categoryText
              }}</VueBadge>
            </div>
          </div>
        </div>
        <BrandMediaContentBox
          v-if="contentDetail.feed.largeImageURL && !contentDetail.feed.videoURL"
          class="feed-media"
          :aspectRatios="propData.media.aspect.by_16_9"
          :mediaSrc="contentDetail.feed.largeImageURL"
          :lazyload="false"
        >
          <div class="card-bottom">
            <VueBadge :radius="20" class="overlay overlay-left">
              <LikeButton
                class="btn-like"
                :status="contentDetail.feed.liked"
                :count.sync="contentDetail.feed.likeCount"
                :contentId="contentDetail.feed.feedId"
                :contentName="contentDetail.feed.title"
              />

              <span v-if="contentDetail.feed.likeCount || contentDetail.feed.likeCount > 0">{{
                contentDetail.feed.likeCount
              }}</span>
            </VueBadge>
            <VueBadge :radius="20" class="overlay overlay-right">{{
              contentDetail.feed.category.categoryText
            }}</VueBadge>
          </div>
        </BrandMediaContentBox>
        <div class="feed-item-summary">
          <div class="feed-item-summary-header">
            <VueText sizeLevel="6" weightLevel="3">{{ contentDetail.feed.title }}</VueText>
            <hr />
            <VueText sizeLevel="5" weightLevel="2">{{
              contentDetail.feed.shortDescription
            }}</VueText>
          </div>
          <div class="feed-item-summary-content">
            <div
              v-html="contentDetail.htmlText"
              :class="[contentDetail.feed.sourceType === 2 && 'listelist-content', 'feed-content']"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="content-action">
      <BrandButton
        v-if="isFromFeed && contentDetail && !displayCompletionButton"
        @click="goToVirtualVisit"
        :size="sizes.xLarge"
        :contentAlignment="constants.flexAlignment.center"
        class="content-action-btn"
        style="margin-bottom: 20px; background: #D02D2C;"
        >ŞEF DÜKKANINDA İZLE</BrandButton
      >
    </div>
    <div v-if="!!hasDoWinFeed" class="content-action">
      <BrandButton
        v-if="!displayCompletionButton"
        @click="onDoWinCompleted"
        :size="sizes.xLarge"
        :contentAlignment="constants.flexAlignment.center"
        class="content-action-btn"
        >BİTİR</BrandButton
      >
      <div v-else>
        <img
          style="width: 150px;"
          :style="{ 'margin-top': displayCompletionButton ? '50%' : '' }"
          src="~@/assets/icons/checkGradient.svg"
          alt="check"
        />
        <VueText style="margin-top: 20px;" sizeLevel="6" weightLevel="3"
          >Yap Kazanı Tamamladınız</VueText
        >
      </div>
    </div>

    <div class="content-action">
      <BrandButton
        v-if="quizStatusType && surveyTakenStatus"
        @click="openQuizPopup"
        :size="sizes.xLarge"
        :contentAlignment="constants.flexAlignment.center"
        class="content-action-btn"
        >TESTİ ÇÖZ</BrandButton
      >
    </div>
    <RosettesQuizPopup
      v-if="showRosetteQuizPopup"
      @closeQuizPopup="openQuizPopup"
      @surveyTakenStatus="getSurveyTakenStatus"
      :rosetteType="contentRosetteType"
      :rosetteInput="rosetteInput"
    />
  </div>
</template>
<script>
import LikeButton from '@/components/brand/FeedbackButton/LikeButton.vue';
import MEDIA_CONSTANTS from '@/constants/media.constants.js';
import VueBadge from '@/components/shared/VueBadge/VueBadge.vue';
import BrandMediaContentBox from '@/components/brand/BrandMediaContentBox/BrandMediaContentBox.vue';
import VueText from '@/components/shared/VueText/VueText';
import { Content } from '@/services/Api/index';
import StorageProps from '@/mixins/storageProps.js';
import gtmUtils from '@/mixins/gtmUtils.js';
import BrandVimeoPlayer from '@/components/brand/BrandVimeoPlayer/BrandVimeoPlayer.vue';
import { EventLog } from '@/services/Api/index';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import StorageHelper from '@/utils/storageHelper';
import RoutesRoot from '@/router/routes/RoutesRoot';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RosettesQuizPopup from './settings/RosettesQuizPopup.vue';
import { validQuizStatusTypes } from '@/modules/rosettes/constants/rosetteConstants';

export default {
  name: 'FeedDetail',
  mixins: [StorageProps, gtmUtils],
  components: {
    VueBadge,
    BrandMediaContentBox,
    BrandVimeoPlayer,
    LikeButton,
    VueText,
    RosettesQuizPopup,
    BrandButton,
  },
  data() {
    return {
      hasDoWinFeed: false,
      displayCompletionButton: false,
      isVisibleAllContent: true,
      contentDetail: null,
      referrerName: 'Bilinmiyor',
      taskFeed: {},
      storageTaskDetail: {},
      isFromFeed: null,
      showRosetteQuizPopup: false,
      rosetteInput: null,
      contentRosetteType: 7,
      quizStatusType: 0,
      surveyTakenStatus: true,
    };
  },

  computed: {
    propData() {
      const media = {
        aspect: MEDIA_CONSTANTS.MEDIA_ASPECT_RATIOS,
        type: MEDIA_CONSTANTS.MEDIA_CONTENT_TYPES,
      };

      return { media };
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from && from.name) {
        if (from.name === 'feed') {
          vm.referrerName = 'Anasayfa';
        } else if (from.name === 'fun') {
          vm.referrerName = 'Yaşam';
        }
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('app/clearFeedTaskDetail');
    new StorageHelper({ id: 'FeedTaskDetail' }).set({});
    next();
  },
  created() {
    this.taskFeed = this.$store.getters['app/getFeedTaskDetail'];
    this.storageTaskDetail = new StorageHelper({ id: 'FeedTaskDetail' }).get();
    this.isFromFeed = (this.storageTaskDetail?.isFromFeed || this.taskFeed?.isFromFeed) ?? false;
    Content.getFeedContent(this.$route.params.id, this.isFromFeed).then(res => {
      if (res && res.data) {
        const {
          Data: { contentDetail },
        } = res.data;

        this.contentDetail = contentDetail;
        this.hasDoWinFeed = contentDetail.feed.hasYk;
        this.rosetteInput = contentDetail.rosetteInput;

        this.quizStatusType = validQuizStatusTypes.includes(this.rosetteInput?.quizStatusType);

        if (this.$route.query && this.$route.query.yk) this.setAppBarTitle('Yap Kazan');
        else if (
          contentDetail &&
          contentDetail.feed &&
          contentDetail.feed.category &&
          contentDetail.feed.category.categoryText
        )
          this.setAppBarTitle(contentDetail.feed.category.categoryText);

        if (this.contentDetail) {
          this.pushDataLayerEvent('contentView', {
            ...contentDetail.feed,
            pageName: this.referrerName,
          });

          let contentPath = `Yaşam/${contentDetail.feed.category.parentCategory?.categoryText}/${contentDetail.feed.category.categoryText}/${contentDetail.feed.title}`;
          EventLog.postEventLog(contentPath, window.location.href);
          if (window.insider_object) {
            if (window.insider_object.user?.custom) {
              window.insider_object.user.custom.feedDetail = {
                category: contentDetail.feed.category.parentCategory?.categoryText,
                subCategory: contentDetail.feed.category.categoryText,
                feed: contentDetail.feed.title,
                date: contentDetail.feed.publishDate,
              };
            }
          }
          window.dataLayer
            .slice()
            .reverse()
            .find(i => i.event === 'commercial_data').page_path =
            'Yaşam / ' +
            contentDetail.feed.category.parentCategory?.categoryText +
            ' / ' +
            contentDetail.feed.category.categoryText +
            ' / ' +
            contentDetail.feed.title;
        }
      }
    });

    if (this.$route.query && this.$route.query.yk) this.setAppBarTitle('Yap Kazan');
  },
  methods: {
    goToVirtualVisit() {
      this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.VirtualVisit.path}`);
    },
    onDoWinCompleted() {
      this.displayCompletionButton = !this.displayCompletionButton;
      this.isVisibleAllContent = !this.isVisibleAllContent;
      window.scrollTo(0, 0);
    },
    openQuizPopup() {
      this.showRosetteQuizPopup = !this.showRosetteQuizPopup;
    },
    getSurveyTakenStatus(status) {
      this.surveyTakenStatus = status != 1;
    },
  },
};
</script>

<style scoped lang="scss">
$overlay-side-space: palette-space-level(20);
$space-10: palette-space-level(10);

.feed-media {
  position: relative;
  display: flex;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  overflow: hidden;
}

.feed-item-container {
  padding-bottom: palette-space-level(10);
  .feed-video-like {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
  }

  .feed-item-summary {
    padding: 10px 20px 0;
    padding-left: 28px;
    &-header {
      margin-bottom: palette-space-level('10');
      hr {
        opacity: 0.5;
        border-top: solid 1px palette-color-level('grey', '20');
      }
      p {
        padding: palette-space-level('10') 0;
      }
    }
    &-content {
      div,
      span,
      h2,
      p {
        padding-bottom: $space-10;
      }

      /deep/ .feed-content {
        img {
          border: none !important;
          border-radius: palette-space-level(3);
        }
        * {
          width: 100%;
          word-break: break-word;
          height: auto;
        }
      }
    }
  }
}

.content-action {
  text-align: -webkit-center;
  .content-action-btn {
    width: 80% !important;
    margin-bottom: 30px;
  }
}

.overlay {
  -webkit-box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
  max-height: 34px;
  display: flex;
  align-items: center;
  font-size: palette-font-size-level(4);
  font-weight: palette-font-weight-level(3);
  /* &.overlay-video {
    margin-top: 15px;
  } */
  &.overlay-left {
    left: $overlay-side-space;
    padding: 5px;
    justify-content: center;
    .btn-like {
      display: flex;
      position: relative;
      top: 1px;
    }
    svg {
      height: 16px;
    }
    span {
      padding-left: palette-space-level(5);
      padding-right: 2px;
    }
  }
  &.overlay-right {
    right: $overlay-side-space;
    padding: $space-10;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.card-bottom {
  justify-content: space-between;
  display: flex;
  position: absolute;
  bottom: 15px;
  width: 100%;
  padding: 0 20px;
}
.card-video-bottom {
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
/deep/ .listelist-content {
  img {
    width: auto !important;
    max-width: 100%;
    margin-bottom: 20px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    padding-bottom: 0 !important;
    padding-top: 10px;
    line-height: 1.25;
  }
  h1 {
    font-size: 2em;
    margin-bottom: 0.67em;
  }
  h2 {
    font-size: 1.5em;
    margin-bottom: 0.83em;
  }
  h3 {
    font-size: 1.17em;
    margin-bottom: 1em;
  }
  h4 {
    font-size: 1em;
    margin-bottom: 1.33em;
  }
  h5 {
    font-size: 0.83em;
    margin-bottom: 1.33em;
  }
  h6 {
    font-size: 0.67em;
    margin-bottom: 2.33em;
  }
  p {
    margin-bottom: 20px;
    padding-bottom: 0 !important;
    line-height: 1.44;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-bottom: 1em;
    padding-left: 40px;
    list-style: square;
    li {
      margin-bottom: 0.5em;
    }
  }
}
</style>
